<!-- eslint-disable max-len -->
<template>
  <div class='admin-dashboard-approval-main-wrapper'>
    <div class="admin-dashboard-accordian-with-accept-reject-wrapper">
        <div class="admin-dashboard-accordian-main-wrapper">
            <a-collapse class="admin-dashboard-collapsable-wrapper"
            v-model:activeKey="activeKey" accordion
            :expand-icon-position="expandIconPosition">
                <a-collapse-panel
                @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.CLIENT_DETAILS)"
                key="1" header="Client Details">
                  <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Name:</div>
                     <div class="client-details-key-text">DOB:</div>
                     <div class="client-details-key-text">PAN No:</div>
                     <div class="client-details-key-text">UID No:</div>
                     <div class="client-details-key-text">Mobile No:</div>
                     <div class="client-details-key-text">Email ID:</div>
                     <div class="client-details-key-text">GENDER:</div>
                     <div class="client-details-key-text">MARITAL</div>
                     <div class="client-details-key-text">STATUS:</div>
                     <div class="client-details-key-text">Documents</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">Smt RUTA BHALCHANRA PANDIT</div>
                     <div class="client-details-response-text">27 Mar 1973</div>
                     <div class="client-details-response-text">AHFPP5595H</div>
                     <div class="client-details-response-text">3011</div>
                     <div class="client-details-response-text">9096082923</div>
                     <div class="client-details-response-text">rutapandit27@gmail.com</div>
                     <div class="client-details-response-text">FEMALE</div>
                     <div class="client-details-response-text">MARRIED</div>
                     <div class="client-details-response-text">UNDER REVIEW</div>
                     <div class="client-details-response-text"><a href="#"
                        style="color: #0d3c6b;
                        text-decoration: underline;
                        font-weight: 500;">View PAN</a></div>
                    </div>
                    </div>
                  </div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.REGULATORY_DISCLOSURE)" key="2" header="Regulatory Disclosure">
                <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.CORRESPONDENCE)" key="3" header="Correspondence">
                    <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Email:</div>
                     <div class="client-details-key-text">Mobile:</div>
                     <div class="client-details-key-text">Address:</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">test@gmail.com</div>
                     <div class="client-details-response-text">1234567890</div>
                     <div class="client-details-response-text">lorem ipsum</div>
                    </div>
                    </div>
                  </div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.PERMANENT)" key="4" header="Permanent">
                  <div class="collapse-pannel-inner-content">
                    {{ text }}
                  </div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.PRIMARY_BANK_DETAILS)" key="5" header="Primary Bank Details">
                  <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Bank Name:</div>
                     <div class="client-details-key-text">IFSC:</div>
                     <div class="client-details-key-text">Account number:</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">Kotak Mahindra Bank</div>
                     <div class="client-details-response-text">KOTAK1445</div>
                     <div class="client-details-response-text">188819917818991</div>
                    </div>
                    </div>
                  </div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.NOMINEE_DETAILS)" key="6" header="Nominee Details">
                    <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Name:</div>
                     <div class="client-details-key-text">PAN:</div>
                     <div class="client-details-key-text">Relation:</div>
                     <div class="client-details-key-text">DOB:</div>
                     <div class="client-details-key-text">Share:</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">Rakesh Jakkula</div>
                     <div class="client-details-response-text">HAYBJ9822A</div>
                     <div class="client-details-response-text">Brother</div>
                     <div class="client-details-response-text">09/08/1997</div>
                     <div class="client-details-response-text">50%</div>
                    </div>
                    </div>
                  </div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.ADD_CLIENT_CODE)" key="7" header="Add Client Code">
                  <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.TRADING_PREFERENCE)" key="8" header="Trading Preference">
                  <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.DEMAT_DETAILS)" key="9" header="Demat Details (Existing A/C)">
                  <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.BROKERAGE_DETAILS)" key="10" header="Brokerage Details">
                  <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.ADDITIONAL_BANK_DETAILS)" key="11" header="Additional Bank Details">
                  <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
                <a-collapse-panel @click="onClickclientDetails(APPROVAL_STAGES.APPROVAL_STAGE_TYPE.GUARDIAN_DETAILS)" key="12" header="Guardian Details">
                  <div class="collapse-pannel-inner-content">{{ text }}</div>
                </a-collapse-panel>
            </a-collapse>
        </div>
        <div class="admin-dashboard-accept-reject-main-wrapper">
            <div class="accept-reject-title-with-button">
                <div class="accept-reject-title">RUTA BHALCHANRA PANDIT</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <a-button>Accept KYC</a-button></div>
                <div class="reject-kyc-btn-wrapper"><a-button>Reject KYC</a-button></div>
            </div>
            <div v-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.CLIENT_DETAILS">
            <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">PAN Card</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
                <div class="panCard-image">
                    <img style="max-width: 400px;width: 100%;object-fit: cover;"
                    src='../../../assets/panCard.jpeg' />
                </div>
                <div class="rejection-reason-main-wrapper">
                    <div class="enter-your-rejection-text">
                        Enter your Rejection reason
                    </div>
                    <div class="rejection-reason-checkbox-wrapper">
                        <a-checkbox-group v-model:value="checkboxValue" style="width: 100%">
                            <div class="checkbox-individual-wrappers">
                                <a-checkbox style="width:50%" value="A">PAN Card image not visible
                                </a-checkbox>
                                <a-checkbox value="B">Date of Birth on PAN Card is not visible
                                </a-checkbox>
                            </div>
                            <div class="checkbox-individual-wrappers">
                                <a-checkbox style="width:50%" value="C">
                                    Name on PAN Card is not visible
                                </a-checkbox>
                                <a-checkbox value="D">Father Name on PAN Card is not visible
                                </a-checkbox>
                            </div>
                            <div class="checkbox-individual-wrappers">
                                <a-checkbox value="E"><a-input
                  class='admin-approval-input-field'
                  v-model:value='reason'
                  placeholder='Others (Specific reason)'
                >
                </a-input></a-checkbox>
                                </div>
                        </a-checkbox-group>
                    </div>
                </div>
                <div class="accept-reject-button-wrapper">
                <div class="accept-kyc-btn-wrapper"><a-button>Accept</a-button></div>
                <div class="reject-kyc-btn-wrapper"><a-button>Reject</a-button></div>
            </div>
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.REGULATORY_DISCLOSURE">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Regulatory Disclosure</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.CORRESPONDENCE">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Correspondence</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Email:</div>
                     <div class="client-details-key-text">Mobile:</div>
                     <div class="client-details-key-text">Address:</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">test@gmail.com</div>
                     <div class="client-details-response-text">1234567890</div>
                     <div class="client-details-response-text">lorem ipsum</div>
                    </div>
                    </div>
                  </div>
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.PERMANENT">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Permanent</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.PRIMARY_BANK_DETAILS">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Permanent</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Bank Name:</div>
                     <div class="client-details-key-text">IFSC:</div>
                     <div class="client-details-key-text">Account number:</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">Kotak Mahindra Bank</div>
                     <div class="client-details-response-text">KOTAK1445</div>
                     <div class="client-details-response-text">188819917818991</div>
                    </div>
                    </div>
                  </div>
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.NOMINEE_DETAILS">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Nominee Details</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              <div class="collapse-pannel-inner-content">
                    <div style="display:flex">
                    <div class='client-details-key-with-wrapper'>
                     <div class="client-details-key-text">Name:</div>
                     <div class="client-details-key-text">PAN:</div>
                     <div class="client-details-key-text">Relation:</div>
                     <div class="client-details-key-text">DOB:</div>
                     <div class="client-details-key-text">Share:</div>
                    </div>
                    <div class='client-details-response-with-wrapper'>
                     <div class="client-details-response-text">Rakesh Jakkula</div>
                     <div class="client-details-response-text">HAYBJ9822A</div>
                     <div class="client-details-response-text">Brother</div>
                     <div class="client-details-response-text">09/08/1997</div>
                     <div class="client-details-response-text">50%</div>
                    </div>
                    </div>
                  </div>
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.ADD_CLIENT_CODE">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Add Client Code</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.TRADING_PREFERENCE">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Trading Preference</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.DEMAT_DETAILS">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Demat Details (Existing A/C)</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.BROKERAGE_DETAILS">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Brokerage Details</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.ADDITIONAL_BANK_DETAILS">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Additional Bank Details</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
            <div v-else-if="type === APPROVAL_STAGES.APPROVAL_STAGE_TYPE.GUARDIAN_DETAILS">
              <div class="pan-card-title-with-icons">
                <div class="accept-reject-title">Guardian Details</div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-in-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
                <div class="accept-kyc-btn-wrapper" style="margin-right:15px">
                    <zoom-out-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }" /></div>
                <div class="reject-kyc-btn-wrapper"><reload-outlined :style="{
                  fontSize: '20px',
                  color: '#000000',
                  cursor: 'pointer',
                }"/></div>
            </div>
            <div class="pan-card-image-with-accept-reject-btn">
              Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  watch,
  computed,
} from 'vue';
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  ReloadOutlined,
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import APPROVAL_STAGES from '../../../constants/approvalStages';
// import { message } from 'ant-design-vue';
// import services from '../../../services/apis';

export default {
  components: {
    ZoomInOutlined,
    ZoomOutOutlined,
    ReloadOutlined,
  },
  setup() {
    const activeKey = ref([]);
    const store = useStore();
    const expandIconPosition = ref('right');
    const text = 'Donec tincidunt consectetur orci at dignissim. Proin auctor aliquam justo, vitae luctus odio pretium scelerisque.';
    const reason = ref('');
    const checkboxValue = ref([]);
    const type = ref('');
    const clientId = ref('');
    onMounted(() => {
      window.scrollTo(0, 0);
      type.value = ('Correspondence');
    });
    watch(() => {
      clientId.value = computed(
        () => store.getters['onboardingStore/getClientId'],
      ).value;
    });
    const onClickclientDetails = (params) => {
      // visible.value = true;
      type.value = params;
      console.log('params :>> ', params);
    };
    return {
      activeKey,
      text,
      expandIconPosition,
      reason,
      checkboxValue,
      onClickclientDetails,
      type,
      APPROVAL_STAGES,
    };
  },
};
</script>
<style lang='scss'>
@import '../styles/adminDashboardApproval.scss';
</style>
